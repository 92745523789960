import { Badge } from '@mui/material'
import { Cog, ComplaintIcon, House, OrderBagIcon, ServicesIcon, TeamMemberIcon } from 'assets/icons'
import { Drawer } from 'components/drawer/Drawer'
import { Header } from 'components/header/Header'
import { Websocket } from 'components/websocket/Websocket'
import { WsStatus } from 'components/websocket-status/WsStatus'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { wsTopics } from 'httpServices/wsTopics'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { addBirthday } from 'redux/birthday/BirthdaySlice'
import { fetchBirthdays } from 'redux/birthday/thunks'
import { selectNumberOfComplaintsByMultipleComplaintStatus } from 'redux/complaint/selectors'
import { BirthdayBookingDetailsDTO } from 'types/api'
import { ComplaintStatus, Permissions } from 'types/api.enum'
import { DrawerItem } from 'types/drawer-item'
import { FEATURE_TOGGLE_CODE, StatusType } from 'utils/enums'

import { useFeatureToggle } from '../../hooks/useFeatureToggle'
import { ErrorBlock } from '../error-block/ErrorBlock'
import { StyledContentContainer, StyledPageContainer } from './style'

interface LayoutProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
    showPreparationTime: boolean
}

export const Layout = ({ children, showPreparationTime }: LayoutProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [drawerItemSelectedIndex, setDrawerItemSelectedIndex] = useState(0)
    const [searchParams] = useSearchParams()
    const enabledCollaborators = useFeatureToggle([FEATURE_TOGGLE_CODE.BOB_COLLABORATEURS])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const [wsStatus, setWsStatus] = useState(StatusType.ERROR)
    const displayNotificationSticker = useAppSelector((state) => state.birthdays.existsStandbyBirthday)
    const complaintNumberInWaitingStatus = useAppSelector((state) =>
        selectNumberOfComplaintsByMultipleComplaintStatus(state, [
            ComplaintStatus.WAITING_RESTAURANT,
            ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER,
            ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER,
            ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER,
        ]),
    )
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const birthdaysTopic = wsTopics.birthdayBookingsByRestaurant(restaurantFrNumber)
    const dispatch = useAppDispatch()

    const buttons: DrawerItem[] = [
        {
            titleKey: 'page.home.title',
            icon: <House />,
            urlKey: 'page.home.route',
        },
        {
            titleKey: 'page.orders.title',
            icon: <OrderBagIcon size={21} />,
            urlKey: 'page.orders.route',
            permission: Permissions.SHOW_ORDER,
        },
        {
            titleKey: 'page.complaints.title',
            icon: (
                <Badge
                    badgeContent={complaintNumberInWaitingStatus}
                    color="error"
                    sx={{
                        '& .MuiBadge-badge': {
                            fontSize: '10px',
                            height: '15px',
                            minWidth: '15px',
                            padding: '0 5px',
                        },
                    }}
                >
                    <ComplaintIcon />
                </Badge>
            ),
            urlKey: 'page.complaints.route',
            permission: Permissions.SHOW_COMPLAINT,
        },
        {
            titleKey: 'page.services.title',
            icon: (
                <Badge color="error" variant="dot" invisible={!displayNotificationSticker}>
                    <ServicesIcon size={21} />
                </Badge>
            ),
            urlKey: 'page.services.route',
            permission: Permissions.SHOW_SERVICE,
        },
        {
            titleKey: 'page.parameters.title',
            icon: <Cog />,
            urlKey: 'page.parameters.route',
            bottom: true,
        },
    ]

    if (enabledCollaborators) {
        buttons.splice(4, 0, {
            titleKey: 'page.collaborators.title',
            icon: <TeamMemberIcon />,
            urlKey: 'page.collaborators.route',
            permission: Permissions.MANAGE_EMPLOYEES,
        })
    }

    useEffect(() => {
        const getTitleFromUrl = () => {
            const buttonIndex = buttons.findIndex((button) => {
                if (t(button.urlKey) === '/') {
                    return window.location.pathname === t(button.urlKey)
                }
                return window.location.pathname.includes(t(button.urlKey))
            })
            return buttonIndex !== -1 ? buttonIndex : 0
        }
        setDrawerItemSelectedIndex(getTitleFromUrl())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, drawerItemSelectedIndex, searchParams, t])

    useEffect(() => {
        try {
            // @ts-ignore
            dispatch(fetchBirthdays(restaurantFrNumber))
        } catch (_error) {
            setError(true)
        }
    }, [restaurantFrNumber, dispatch])

    const onDrawerItemClick = (button: DrawerItem, index: number) => {
        setDrawerItemSelectedIndex(index)
        navigate(t(button.urlKey))
    }

    return (
        <>
            <WsStatus status={wsStatus} />
            <Websocket
                topics={[birthdaysTopic]}
                onMessage={(message, topic) => {
                    if (topic === birthdaysTopic) {
                        dispatch(addBirthday(message as BirthdayBookingDetailsDTO))
                    }
                }}
                onConnect={() => setWsStatus(StatusType.FINISHED)}
                onConnectFailure={() => setWsStatus(StatusType.ERROR)}
                onDisconnect={() => setWsStatus(StatusType.ERROR)}
            />
            <Drawer
                buttons={buttons}
                drawerItemSelectedIndex={drawerItemSelectedIndex}
                onDrawerItemClick={onDrawerItemClick}
            >
                <StyledPageContainer>
                    <Header
                        title={t(buttons[drawerItemSelectedIndex].titleKey)}
                        showPreparationTime={showPreparationTime}
                    />
                    <StyledContentContainer>{error ? <ErrorBlock /> : children}</StyledContentContainer>
                </StyledPageContainer>
            </Drawer>
        </>
    )
}
