import { Button } from 'components/button/Button'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { SPACING } from 'styles/tokens'
import { Text } from 'styles/typography'

export const Container = styled.div<{ error?: boolean }>`
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    border-radius: 5px;
    margin-top: 43px;
    padding: 24px;
    min-height: calc(100% - 93px);
    height: fit-content;

    ${(props) => props.error && `padding-bottom: 90px;`};
`

export const Grid = styled.table`
    width: 100%;
    border-collapse: collapse;
`

export const Row = styled.tr<{ error?: boolean }>`
    ${(props) => props.error && `background-color: ${colors.redLight}`};

    & > td {
        ${(props) =>
            props.error
                ? `border-left-color: ${colors.redLight};
        border-right-color: ${colors.redLight};`
                : `border-left: none;
        border-right: none;`}
    }
`

export const TitleText = styled(Text)`
    font-family: ${fonts.flame};
    color: ${colors.brownDark};
`

export const Column = styled.td`
    padding: 10px;
    border: 1px solid ${colors.greyLight};
`

export const ColumnTitle = styled.td<{ radiusRight?: boolean; radiusLeft?: boolean }>`
    background-color: ${colors.beige};
    padding: 10px;
    ${(props) => props.radiusRight && 'border-radius: 0px 5px 0px 0px;'}
    ${(props) => props.radiusLeft && 'border-radius: 5px 0px 0px 0px;'}
`

export const IconTextContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const Center = styled.div`
    margin: 0 auto;
    margin-top: 200px;
`

export const StyledIconAndLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

export const StyledIconContainer = styled.div`
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
`

export const StyledButton = styled(Button)`
    float: right;
`

export const StyledButtonContainer = styled.div`
    display: flex;
    height: ${SPACING.XXL};
`
