import { colors } from 'styles/colors'
import { StatusType } from 'utils/enums'

import { StyledDot, StyledTextStatus, StyledTile } from './style'

interface StatusProps {
    type: StatusType
    text?: string
}

const statusColors: Record<string, { dot: string; background: string }> = {
    [StatusType.TO_DO]: { dot: colors.orange, background: colors.orangeLight },
    [StatusType.IN_PROGRESS]: { dot: colors.orange, background: colors.orangeLight },
    [StatusType.FINISHED]: { dot: colors.green, background: colors.greenLight },
    [StatusType.ERROR]: { dot: colors.red, background: colors.redLight },
}

export const Status = ({ type, text }: StatusProps) => {
    const currentStatus = statusColors[type]

    return (
        <StyledTile color={currentStatus.background} isText={!!text}>
            <StyledDot color={currentStatus.dot} />
            {!!text && <StyledTextStatus>{text}</StyledTextStatus>}
        </StyledTile>
    )
}
