import { Dropdown } from 'components/dropdown/Dropdown'
import { OrderPreparationTime } from 'components/order-preparation-time/OrderPreparationTime'
import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { Websocket } from 'components/websocket/Websocket'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { wsTopics } from 'httpServices/wsTopics'
import { useTranslation } from 'react-i18next'
import { setSelectedRestaurant, updateRestaurants } from 'redux/restaurant/restaurantSlice'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { colors } from 'styles/colors'
import { RestaurantDTO, RestaurantLightDTO } from 'types/api'
import { Permissions } from 'types/api.enum'
import { DropDownItem } from 'types/dropdown-item'
import { hasPermission } from 'utils/util'

import { StyledContainer, StyledH1, StyledHeader, StyledTitleContainer } from './style'

interface HeaderProps {
    title: string
    showPreparationTime?: boolean
}

export const Header = ({ title, showPreparationTime = false }: HeaderProps) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const selectedFrNumber: string = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const restaurants: RestaurantLightDTO[] = useAppSelector((state) => state.restaurant.restaurants)
    const preparationTime: number = useAppSelector(
        (state) => state.restaurant.restaurantsByFrNumber[selectedFrNumber]?.preparationTime,
    )
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    const data: Array<DropDownItem> = restaurants.map((restaurant) => ({
        value: restaurant,
        label: restaurant.name,
        labelOnSelect: t('burgerKing') + ' ' + restaurant.name,
    }))

    const onChangeDropdown = (item: DropDownItem) => {
        dispatch(setSelectedRestaurant(item.value.frNumber))
    }

    return (
        <StyledContainer>
            <Websocket
                topics={[wsTopics.restaurant(selectedFrNumber)]}
                onMessage={(message) => {
                    dispatch(updateRestaurants(message as RestaurantDTO))
                }}
            />
            <StyledHeader>
                <StyledTitleContainer>
                    <StyledH1 color={colors.brown}>{title}</StyledH1>
                    <Dropdown
                        list={data}
                        initIndex={data?.findIndex((item) => item.value.frNumber === selectedFrNumber)}
                        onChange={onChangeDropdown}
                        searchBar
                        searchBarPlaceholder={t('page.home.dropDownPlaceHolder')}
                    />
                </StyledTitleContainer>
                {showPreparationTime && (
                    <ShouldDisplay permission={Permissions.SHOW_PREPARATION_TIME}>
                        <OrderPreparationTime
                            preparationTime={preparationTime}
                            isClickable={hasPermission(userPermissions, Permissions.MANAGE_PREPARATION_TIME)}
                        />
                    </ShouldDisplay>
                )}
            </StyledHeader>
        </StyledContainer>
    )
}
